import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import SearchableDropDown from '../../components/searchableDropDown';
import ErrorView from '../../components/errorView';
import { productdropdownlist } from '../../actions';

let ProductDropdown = ({ ondropdownChange, value, placeholder, errMessage, filterBy, dontPreload, isLabel }) => {
    const dispatch = useDispatch();

    let productdropdownlist_res = useSelector(state => state.productdropdownlist_res);

    let [searchList, setSearchList] = React.useState([]);
    let [isData, setIsData] = React.useState();

    React.useEffect(() => {
        let data = {};
        data.limit = 50000;
        data.filter = filterBy;
        if (!dontPreload) {
            setSearchList([]);
            dispatch(productdropdownlist(data));
            setIsData(true);
        }
    }, [filterBy, value]);

    React.useEffect(() => {
        let options = []
        if (productdropdownlist_res && productdropdownlist_res.list && productdropdownlist_res.list.length > 0 && !dontPreload) {
            productdropdownlist_res.list.map(item => options.push({ value: item._id, label: item.name, items: item }))
            setSearchList(options);
        } else {
            setIsData(false);
        }
    }, [productdropdownlist_res]);

    return (
        <div>
            {isLabel ? <label className="control-label">Product</label> : null}
            <SearchableDropDown
                value={value}
                noData={isData}
                onChange={ondropdownChange}
                options={searchList}
                instanceId="product-id"
                prefixValue="product"
                placeholder={placeholder || "Product"}
            />
            <ErrorView message={errMessage} />
        </div>
    )
}



export default ProductDropdown;