import React, { Component } from 'react';
import { connect } from 'react-redux';
import { advanceManyAction, advanceList, advanceAction, advance_export } from '../../actions';
import ButtonWithItag from '../../components/buttonwithTagi';
import toastr from 'reactjs-toastr';
import Inputfield from '../../components/inputfields';
import Pagination from '../../components/pagination/index';
import Entries from '../../components/entries/entires';
import Userdropdown from '../userManagement/userDropdwon';
import { errorRender, fileDownload, isPermission } from '../../helpers/functions';
import 'reactjs-toastr/lib/toast.css';
require('react-datetime');
var dateFormat = require('dateformat');

let itemsPerPage = 50;
let currentPage = 1;
let sortBy = 'createdAt';
let sort = 'desc';

let statusCode = {
    accountsApproved: "Approved",
    approved: "Pending Accounts",
    saved: "Pending",
    rejected: "Rejected"
};
class AdvanceApproval extends Component {
    state = {
        isExportLoader: false,
        isLoading: false,
        agent: '',
        totalCount: 0,
        isFilter: false,
        advanceData: [],
        selectAll: false,
        currentAdvanceDetail: {},
        valid: false,
        selectionCount: 0,
        userType: '',
        commentsByApprover: '',
        advanceStatus: '',
        startdate: '',
        enddate: '',
        advanceStartdate: '',
        advanceEnddate: ''
    }

    componentDidMount() {
        this.loadResult();
    }

    componentWillUnmount() {
        currentPage = 1;
        itemsPerPage = 50;
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    onChange = (e) => {
        const re = /([^\s]+)/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [e.target.name]: e.target.value });
        }
        // this.setState({ [e.target.name]: e.target.value });
    }

    handleChange = (name, e) => {
        this.setState({ [name]: e.target.value });
    }

    approveAdvance = (req) => {

        if (req === "approved") {
            this.setState({ actionShow: "Approved" })
        }

        if (req === "rejected") {
            this.setState({ actionShow: "Rejected" })
        }

        if (this.state.amount && this.state.amount.length === 0) {
            toastr.error("Amount is Required", 'Error', {
                displayDuration: 5000
            })
        } else if (!/^\d+$/.test(this.state.amount)) {
            toastr.error("Only Numbers Accepted", 'Error', {
                displayDuration: 5000
            })
        } else if (this.state.currentAdvanceDetail.advanceRequested < this.state.amount) {
            toastr.error("Amount should be smaller then requested Amount", 'Error', {
                displayDuration: 5000
            })
        } else {
            let data = {
                commentsByApprover: this.state.commentsByApprover,
                advanceStatus: req,
                advanceApproved: this.state.amount,
                approverId: localStorage.getItem("userId")
            }
            this.props.advanceAction(data, this.state.currentAdvanceDetail._id);
        }

    }

    advanceMultiAction = (req) => {
        let ids = [];

        if (req === "approved") {
            this.setState({ actionShow: "Approved" })
        }
        if (req === "rejected") {
            this.setState({ actionShow: "Rejected" })
        }

        this.state.advanceData.forEach((el) => {
            if (el.checked) {
                ids.push(el._id)
            }
        });

        let data = {
            ids: ids,
            advanceStatus: req
        };

        this.setState({ isLoading: true, isFilter: false });
        this.props.advanceManyAction(data);
    }

    loadResult = () => {

        if (this.state.isFilter === true) {
            currentPage = 1;
        }

        let data2 = {}
        data2.filter = {
            agent: this.state.agent && this.state.agent.value ? this.state.agent.value : undefined,
            createdAt: {
                from: this.state.startdate !== "" ? this.state.startdate : undefined,
                to: this.state.enddate !== "" ? this.state.enddate : undefined
            }
        }

        data2.advanceDate = { from: this.state.advanceStartdate !== "" ? this.state.advanceStartdate : undefined, to: this.state.advanceEnddate !== "" ? this.state.advanceEnddate : undefined }
        data2.advanceStatus = this.state.advanceStatus !== "" ? this.state.advanceStatus : undefined;
        data2.skip = (currentPage - 1) * itemsPerPage;
        data2.limit = itemsPerPage;
        data2.sortBy = sortBy;
        data2.sort = sort;
        this.setState({ isLoading: true, isFilter: false, selectionCount: 0, selectAll: false });
        this.props.advanceList(data2);
    }

    resetfilter = () => {
        let data2 = {}
        data2.skip = (currentPage - 1) * itemsPerPage;
        data2.limit = itemsPerPage;
        this.setState({ isLoading: true, isFilter: false, agent: null, advanceStatus: '', startdate: '', enddate: '', advanceStartdate: '', advanceEnddate: '' })
        this.props.advanceList(data2);
    }

    ondropdownChangeagent = (e, t) => {
        this.setState({ [e]: t });
    }

    toggleFilter = () => this.setState({ isFilter: !this.state.isFilter })

    sortBy = (key) => {
        sortBy = key;
        if (sort === 'asc') {
            sort = 'desc';
        } else {
            sort = 'asc';
        }
        this.loadResult();
    }

    handleNext = () => {
        currentPage = currentPage + 1;
        this.loadResult();
    }

    handlePrevious = () => {
        currentPage = currentPage - 1;
        this.loadResult();
    }

    handlePage = (page) => {
        currentPage = page;
        this.loadResult();
    }

    checkEntry = (event, item) => {

        item.checked = event.target.checked;

        if (event.target.checked) {
            this.setState({ selectionCount: this.state.selectionCount + 1 })
        } else {
            this.setState({ selectionCount: this.state.selectionCount - 1 })
        }

        if (this.state.advanceData.length === this.state.selectionCount) {
            this.setState({ selectAll: true });
        } else {
            this.setState({ selectAll: false });
        }

    }

    checkAllEntry = (event) => {
        this.setState({ selectionCount: 0, selectAll: event.target.checked });
        this.state.advanceData.forEach((el, idx) => {
            if (el.advanceStatus !== "rejected" && el.advanceStatus !== "accountsApproved") {
                if (event.target.checked) {
                    this.setState({ selectionCount: this.state.selectionCount + 1 });
                } else {
                    this.setState({ selectionCount: 0 })
                }
                el.checked = event.target.checked
            }
        })
        this.forceUpdate();
    }

    handleChangeiItemsPerPage = (items) => {
        currentPage = 1;
        itemsPerPage = items;
        this.loadResult();
    }

    advanceDetails = (item) => this.setState({ currentAdvanceDetail: item, amount: '', commentsByApprover: item.commentsByApprover ? item.commentsByApprover : '' });

    shouldComponentUpdate(nextProps) {

        if (nextProps !== this.props) {
            if (this.state.isLoading) {
                this.setState({ isLoading: false });
            }

            if (nextProps.advanceManyActionData !== undefined && nextProps.advanceManyActionData.success) {
                this.setState({ selectAll: false, selectionCount: 0 })
                this.loadResult();
                nextProps.advanceManyActionData.success = null;
                toastr.success("Action has been applied successfully", 'Success', {
                    displayDuration: 5000
                });
            }

            fileDownload(nextProps, "advance_export_res", "fileName");

            if (nextProps.advance_export_res) {
                this.setState({
                    isExportLoader: false
                });
            }

            errorRender(nextProps, "advance_export_res");
            errorRender(nextProps, "advanceManyActionData");

            if (nextProps.advanceListData !== undefined && nextProps.advanceListData.success) {
                if (nextProps.advanceListData.count > 0) {
                    this.setState({ advanceData: nextProps.advanceListData.list, totalCount: nextProps.advanceListData.count, isLoading: false });
                } else {
                    this.setState({ advanceData: [], totalCount: 0, isLoading: false });
                }
                nextProps.advanceListData.success = null;
            }

            if (nextProps.advanceActionData !== undefined && nextProps.advanceActionData.success) {
                nextProps.advanceActionData.success = null;
                document.getElementById("cancelModel").click()
                toastr.success(`Advance Has been ${this.state.actionShow} Succesfully`, 'Success', {
                    displayDuration: 5000
                });
                this.loadResult();
            }
        }

        return true;
    }


    export = () => {
        this.setState({
            isExportLoader: true
        });
        if (this.state.isFilter === true) {
            currentPage = 1;
        }
        let data = {}
        data.filter = {
            agent: this.state.agent && this.state.agent.value ? this.state.agent.value : undefined,
            createdAt: {
                from: this.state.startdate !== "" ? this.state.startdate : undefined,
                to: this.state.enddate !== "" ? this.state.enddate : undefined
            }
        }
        data.advanceDate = { from: this.state.advanceStartdate !== "" ? this.state.advanceStartdate : undefined, to: this.state.advanceEnddate !== "" ? this.state.advanceEnddate : undefined }
        data.advanceStatus = this.state.advanceStatus !== "" ? this.state.advanceStatus : undefined;
        data.sortBy = sortBy;
        data.sort = sort;
        this.props.advance_export(data);
    }

    render() {
        return (
            <main className="main-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-sm-12 mt-4">
                            <div className="card card-shadow mb-4">
                                <div className="card-header">
                                    <div className="card-title">
                                        Advance Approval
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div id="bs4-table_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4  p-0">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6">
                                                {this.state.totalCount !== undefined ?
                                                    <Entries
                                                        itemsPerPage={itemsPerPage}
                                                        handleChangeiItemsPerPage={
                                                            this.handleChangeiItemsPerPage
                                                        }
                                                    />
                                                    : ""}
                                            </div>
                                            <div className="col-sm-12 col-md-6">
                                                <div id="bs4-table_filter" className="dataTables_filter d-flex flex-row justify-content-end align-items-center">
                                                    {this.state.selectionCount > 0 ?
                                                        <ButtonWithItag onclick={ev => { if (window.confirm(`Are you sure you want to approve the amount for Account Approval ${this.state.selectionCount} Requests ?`)) this.advanceMultiAction(this.state.currentAdvanceDetail.advanceStatus === "approved" ? "accountsApproved" : 'accountsApproved') }} type="button" classNameI="fa fa-check-circle-o" className="btn btn-primary action-btn mr-2" data-toggle="tooltip" data-placement="top" title="Approve" data-original-title="Delete" /> : null}
                                                    {this.state.selectionCount > 0 ? <ButtonWithItag onclick={ev => { if (window.confirm(`Are you sure you want to reject the amount for Account Approval ${this.state.selectionCount} Requests ?`)) this.advanceMultiAction("rejected") }} type="button" classNameI="fa fa-times-circle-o" className="btn btn-danger action-btn mr-2" data-toggle="tooltip" data-placement="top" title="Reject" data-original-title="Delete" /> : null}
                                                    <div onClick={() => this.loadResult()} className="mr-3">
                                                        <i className={this.state.isLoading ? "fa-spin fa-2x text-primary fa fa-refresh" : "fa-2x text-primary fa fa-refresh"}></i>
                                                    </div>
                                                    {
                                                        this.state.advanceData && this.state.advanceData.length > 0 ?
                                                            <ButtonWithItag classNameI="fa tai-ico-Export" type="button" onclick={this.export} disabled={this.state.isExportLoader} className="btn btn-primary mr-2" data-toggle="tooltip" data-placement="top" title="Export as a file" data-original-title="Click to Download all" />
                                                            : null
                                                    }
                                                    <ButtonWithItag classNameI="ti-filter" onclick={this.toggleFilter} type="button" className="btn btn-primary navbar-toggler right-sidebar-toggler mr-1" data-toggle="tooltip" data-placement="top" title="Filter" data-original-title="Click for Filter option" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" style={{ display: "none" }} aria-hidden="true">
                                            <div className="modal-dialog modal-lg">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="myLargeModalLabel">
                                                            Advance Details
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="close"
                                                            data-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="row ">
                                                            <div className="col-sm-4">
                                                                <div>
                                                                    <div >
                                                                        <strong>  User Name{" "}</strong> <br />
                                                                        <span>
                                                                            {this.state.currentAdvanceDetail && this.state.currentAdvanceDetail.agentId && this.state.currentAdvanceDetail.agentId.name ? this.state.currentAdvanceDetail.agentId.name : "N/A"}
                                                                        </span>{" "}
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <div >
                                                                        <strong> Requested Amount{" "}</strong> <br />
                                                                        <span>
                                                                            {this.state.currentAdvanceDetail && this.state.currentAdvanceDetail.advanceRequested && this.state.currentAdvanceDetail.advanceRequested ? `${this.state.currentAdvanceDetail.advanceRequested} \u20A8` : "N/A"}
                                                                        </span>{" "}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div >
                                                                    <div >
                                                                        <strong></strong> <br />
                                                                        <span>

                                                                        </span>{" "}
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div style={{ textAlign: 'center', marginTop: 25 }}>
                                                                    <div >
                                                                        <strong> Approved Amount{" "}</strong> <br />
                                                                        <span>
                                                                            {this.state.currentAdvanceDetail && this.state.currentAdvanceDetail.advanceApproved && this.state.currentAdvanceDetail.advanceApproved ? `${this.state.currentAdvanceDetail.advanceApproved} \u20A8` : "0"}
                                                                        </span>{" "}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4">
                                                                <div style={{ textAlign: 'right' }}>
                                                                    <div >
                                                                        <strong>  Date{" "}</strong> <br />
                                                                        <span>
                                                                            {this.state.currentAdvanceDetail && this.state.currentAdvanceDetail.advanceDateInString && this.state.currentAdvanceDetail.advanceDateInString ? this.state.currentAdvanceDetail.advanceDateInString : "N/A"}
                                                                        </span>{" "}
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div style={{ textAlign: 'right' }}>
                                                                    <div >
                                                                        <strong> Wallet Balance{" "}</strong> <br />
                                                                        <span className="pt-4">
                                                                            {this.state.currentAdvanceDetail && this.state.currentAdvanceDetail.wallet && this.state.currentAdvanceDetail.wallet.walletBalance ? `${this.state.currentAdvanceDetail.wallet.walletBalance} \u20A8` : "0"}
                                                                        </span>{" "}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.currentAdvanceDetail.advanceStatus !== "rejected" && this.state.currentAdvanceDetail.advanceStatus !== "accountsApproved" ?
                                                            <div className="row">
                                                                <div className="mt-4 col-sm-6">
                                                                    <div className="form-group required">
                                                                        <Inputfield pattern="[0-9]*" handleChange={this.handleChange} type="text" name="amount" value={this.state.amount} className="form-control" id="exampleInputName1" ariadescribedby="codeHelp" placeholder="Amount" />
                                                                        <small style={{ fontSize: 12, color: 'red' }}>
                                                                            {' '}
                                                                            {this.state.nameError}{' '}
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </div> : null}
                                                    </div>
                                                    <div className="col-sm-14 text-center">
                                                        <div className="text-left ml-3">
                                                            <strong >  Comments{" "}</strong> <br />
                                                        </div>
                                                        <textarea onChange={(e) => this.handleChange("commentsByApprover", e)}
                                                            type="text"
                                                            name="commentsByApprover"
                                                            maxLength="200"
                                                            value={this.state.commentsByApprover}
                                                            style={{ width: "96%" }}
                                                            placeholder="Comments" />
                                                    </div>
                                                    <div className="modal-footer bg-white text-center justify-content-center">
                                                    {isPermission("advanceApproval", "update") ? 
                                                        <>
                                                        {this.state.currentAdvanceDetail.advanceStatus !== "rejected" && this.state.currentAdvanceDetail.advanceStatus !== "accountsApproved" ?
                                                            <button
                                                                onClick={() => this.approveAdvance(this.state.currentAdvanceDetail.advanceStatus === "approved" ? "accountsApproved" : 'approved')}
                                                                type="button"
                                                                className="btn btn-primary"
                                                            >
                                                                {this.state.currentAdvanceDetail.advanceStatus === "approved" ? "Accounts Approve" : 'Approve'}
                                                            </button> : null}

                                                        {
                                                            this.state.currentAdvanceDetail.advanceStatus !== "rejected" && this.state.currentAdvanceDetail.advanceStatus !== "accountsApproved" ?
                                                                <button
                                                                    onClick={() => this.approveAdvance("rejected")}
                                                                    type="button"
                                                                    className="btn btn-danger"
                                                                >
                                                                    Reject
                                                                </button> : null
                                                        }
                                                        </> : null}
                                                        <button id="cancelModel" type="button" className="btn btn-dark" data-dismiss="modal">
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="table-responsive">
                                                    <table id="bs4-table" className="table table-bordered table-striped dataTable" cellSpacing="0">
                                                        <thead>
                                                            <tr role="row">
                                                                {this.state.advanceData !== undefined && this.state.advanceData.length > 0 ? <th><input type="checkbox" checked={this.state.selectAll} onChange={(e) => this.checkAllEntry(e)} /> </th> : null}
                                                                <th>Advance Approval ID</th>
                                                                <th onClick={() => this.sortBy('advanceDateInString')} className={sort === ''
                                                                    ? 'sorting'
                                                                    : sort === 'asc'
                                                                        ? 'sorting_asc'
                                                                        : 'sorting_desc'}>Date </th>

                                                                <th onClick={() => this.sortBy('agentId.name')} className={sort === ''
                                                                    ? 'sorting'
                                                                    : sort === 'asc'
                                                                        ? 'sorting_asc'
                                                                        : 'sorting_desc'}>User Name</th>
                                                                <th onClick={() => this.sortBy('agentId.type')} className={sort === ''
                                                                    ? 'sorting'
                                                                    : sort === 'asc'
                                                                        ? 'sorting_asc'
                                                                        : 'sorting_desc'}>User Type </th>

                                                                <th onClick={() => this.sortBy('advanceRequested')} className={sort === ''
                                                                    ? 'sorting'
                                                                    : sort === 'asc'
                                                                        ? 'sorting_asc'
                                                                        : 'sorting_desc'}>Advance Amount</th>
                                                                <th onClick={() => this.sortBy('advanceApproved')} className={sort === ''
                                                                    ? 'sorting'
                                                                    : sort === 'asc'
                                                                        ? 'sorting_asc'
                                                                        : 'sorting_desc'}>Approved Amount</th>
                                                                <th onClick={() => this.sortBy('advanceStatus')} className={sort === ''
                                                                    ? 'sorting'
                                                                    : sort === 'asc'
                                                                        ? 'sorting_asc'
                                                                        : 'sorting_desc'}>Status</th>
                                                                <th onClick={() => this.sortBy('walletBalance')} className={sort === ''
                                                                    ? 'sorting'
                                                                    : sort === 'asc'
                                                                        ? 'sorting_asc'
                                                                        : 'sorting_desc'}>Wallet Balance</th>
                                                                <th onClick={() => this.sortBy('createdAt')} className={sort === ''
                                                                    ? 'sorting'
                                                                    : sort === 'asc'
                                                                        ? 'sorting_asc'
                                                                        : 'sorting_desc'}>Created At</th>

                                                                <th >Actions</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {this.state.advanceData !== undefined && this.state.advanceData.length > 0 ? this.state.advanceData.map((item, index) =>
                                                                <tr key={index} role="row" className="odd notification-list">
                                                                    <td> <input type="checkbox" disabled={(item.advanceStatus === "rejected") || (item.advanceStatus === "accountsApproved") ? true : false} checked={item.checked ? item.checked : false} onChange={(e) => this.checkEntry(e, item)} /> </td>
                                                                    <td>{item && item.name ? item.name : "N/A"}</td>
                                                                    <td>{item.advanceDateInString ? item.advanceDateInString : "N/A"}</td>
                                                                    <td>{item.agentId && item.agentId.name ? item.agentId.name : "N/A"}</td>
                                                                    <td>{item.agentId && item.agentId.type ? item.agentId.type : "N/A"}</td>
                                                                    <td>{item.advanceRequested ? item.advanceRequested : "0"}</td>
                                                                    <td>{item.accountsAdvanceApproved ? item.accountsAdvanceApproved : "0"}</td>
                                                                    <td>{item.advanceStatus && statusCode[item.advanceStatus].toUpperCase()}</td>
                                                                    <td>{item.wallet && item.wallet.walletBalance ? item.wallet.walletBalance : "0"}</td>
                                                                    <td>{dateFormat(new Date(item.createdAt), 'mmm d, yyyy H:M')}</td>
                                                                    {isPermission("advanceApproval", "view") ? 
                                                                        <td className="text-center">
                                                                            <ButtonWithItag
                                                                                onclick={
                                                                                    () => this.advanceDetails(item)
                                                                                }
                                                                                classNameI="ti-eye"
                                                                                type="button"
                                                                                className="btn btn-link text-primary action-btn p-0"
                                                                                datatoggle="modal"
                                                                                datatarget=".bd-example-modal-lg"
                                                                                dataplacement="top"
                                                                                title="Order Details"
                                                                                data-original-title="Click to Order Details"
                                                                            />
                                                                        </td>
                                                                    : null}
                                                                </tr>
                                                            ) : <tr><td colSpan="50" className="width: 100%;" style={{ textAlign: "center", }} >No Data Found</td></tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.totalCount !== undefined ?
                                                <Pagination
                                                    handleChangeiItemsPerPage={
                                                        this.handleChangeiItemsPerPage
                                                    }
                                                    handlePage={this.handlePage}
                                                    handleNext={this.handleNext}
                                                    handlePrevious={this.handlePrevious}
                                                    currentPage={currentPage}
                                                    itemsPerPage={itemsPerPage}
                                                    totResult={this.state.totalCount}
                                                    key={currentPage}
                                                />
                                                : ""
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={!this.state.isFilter ? "right-sidebarfilter hidden" : "right-sidebarfilter show"}>
                    <div className="modal-header bg-primary rounded-0">
                        <h6 className="filter-modal-title"> Filter</h6>
                        <button type="button" onClick={this.toggleFilter} className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className="navbar-toggler right-sidebar-toggler pointer">×</span>
                        </button>
                    </div>
                    <div className="card-body">
                        <form>
                            <Userdropdown
                                ondropdownChange={this.ondropdownChangeagent}
                                name="agent"
                                value={this.state.agent}
                                className="form-control"
                                placeholder="User"
                            />
                            <div className="form-group">
                                <label htmlFor="Mobile" className="control-label">Status</label>
                                <select
                                    className="form-control"
                                    name="advanceStatus"
                                    value={this.state.advanceStatus}
                                    onChange={this.onChange}
                                >
                                    <option value="">Select a Status</option>
                                    <option value="accountsApproved">ACCOUNTS APPROVED</option>
                                    <option value="approved">PENDING ACCOUNTS</option>
                                    <option value="rejected">REJECTED</option>
                                    <option value="saved">PENDING</option>
                                </select>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Advance Start Date</label>
                                    <input maxLength="10" type="date" className="form-control" name="advanceStartdate" value={this.state.advanceStartdate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Advance End Date</label>
                                    <input maxLength="10" type="date" className="form-control" name="advanceEnddate" value={this.state.advanceEnddate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Created Start Date</label>
                                    <input maxLength="10" type="date" className="form-control" name="startdate" value={this.state.startdate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                                <div className="form-group col-6">
                                    <label htmlFor="Mobile" className="control-label">Created End Date</label>
                                    <input maxLength="10" type="date" className="form-control" name="enddate" value={this.state.enddate} onChange={this.onChange} id="code" placeholder="Code" />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="col-md-6 mb-4 offset-md-3 text-center">
                        <button onClick={this.loadResult} data-dismiss="modal" type="button" aria-hidden="true" className="btn btn-primary navbar-toggler right-sidebar-toggler pointer mr-1">Apply </button>
                        <button type="button" data-dismiss="modal" onClick={this.resetfilter} className="btn btn-outline-dark">Reset</button>
                    </div>
                </div>

            </main>

        )
    }
}

function mapStateToProps(state) {
    return {
        advanceListData: state.advanceListData,
        advanceActionData: state.advanceActionData,
        advanceManyActionData: state.advanceManyActionData,
        advance_export_res: state.advance_export_res
    };
}

const mapDispatchToProps = dispatch => ({
    advanceList: data => dispatch(advanceList(data)),
    advanceAction: (data, id) => dispatch(advanceAction(data, id)),
    advanceManyAction: data => dispatch(advanceManyAction(data)),
    advance_export: data => dispatch(advance_export(data)),
});

AdvanceApproval = connect(
    mapStateToProps,
    mapDispatchToProps
)(AdvanceApproval);

export default AdvanceApproval;